// import extend function - to add custom rules in vee-validate
import { extend } from "vee-validate";

// import the already available rules
import {
  required,
  max,
  email,
  min,
  min_value,
  numeric,
  max_value,
} from "vee-validate/dist/rules";

// ---- existing rules ----

// required field validation
export const requiredField = extend("required", {
  ...required,
  message: "{_field_} is required.",
});

export const emailIdField = extend("email", {
  ...email,
  message: "Please provide a valid email address.",
});

export const numericField = extend("numeric", {
  ...numeric,
  message: "Only numerics are allowed.",
});

export const maxVal = extend("max", max);

export const minVal = extend("min", min);

export const minInteger = extend("min_value", min_value);

export const maxInteger = extend("max_value", max_value);

/* ---- custom rules ---- */
export const regexValidation = extend("regex", {
  validate(value, { pattern }) {
    const { pattern: regexPattern, description: errorMessage } =
      dynamicValidationRule(value, pattern);
    const regex = new RegExp(regexPattern, "u");

    // Ensure description is always a string
    const errorMessageFormatted =
      Array.isArray(errorMessage) && errorMessage?.length
        ? errorMessage.join("")
        : errorMessage;

    return regex.test(value) ? true : errorMessageFormatted;
  },
  params: ["pattern", "description"],
});

function dynamicValidationRule(value, validationId) {
  if (!value) return { pattern: true, description: "" };
  const inputValidations = [
    {
      Validation_Id: 1,
      Validation_Name: "Alphabet with Space",
      Regular_Expression: "^[a-zA-Z ]+$",
      Description: "Allows only alphabets and spaces.",
    },
    {
      Validation_Id: 2,
      Validation_Name: "Alphanumeric with Space",
      Regular_Expression: "^[a-zA-Z0-9 ]+$",
      Description: "Allows letters, numbers, and spaces.",
    },
    {
      Validation_Id: 3,
      Validation_Name: "Common Input Alpha Validation",
      Regular_Expression:
        "^[\\p{L}\\p{M}\\s.,#\\+&\\/():'ñ’!@#$%^&*±_=|;?\"\\-]+$",
      Description:
        "Allows only alphabets, spaces, and specific symbols (., # + & / - ( ): ' ñ d’ ! @ # ± $ % ^ & * _ =).",
    },
    {
      Validation_Id: 4,
      Validation_Name: "Common Input Alphanumeric Validation",
      Regular_Expression:
        "^[\\p{L}\\p{M}\\p{Nd}\\s.,#\\+&\\/():'ñ’!@#$%^&*±_=|;?\"\\-]+$",
      Description:
        "Allows alphanumeric characters, spaces, and specific symbols (., # + & / - ( ): ' ñ d’ ! @ # ± $ % ^ & * _ =).",
    },
    {
      Validation_Id: 5,
      Validation_Name: "All Inputs Allowed",
      Regular_Expression: "^.*$",
      Description: "All inputs are allowed.",
    },
  ];
  // Find the selected validation object
  const selectedValidation = inputValidations.find(
    (validation) => validation.Validation_Id == validationId
  );
  // Return the custom rule with both pattern and description
  return {
    pattern: selectedValidation.Regular_Expression,
    description: selectedValidation.Description,
  };
}

// maximum value validation with customizing message
export const maxIntegerValidation = extend("maximum", (value, args) => {
  if (parseInt(value, 10) <= parseInt(args[0], 10)) {
    return true;
  }
  return args && args[1] // when custom msg is available we can present that
    ? args[1] // custom msg
    : "The value should be less than or equal to " + args; // default msg
});

// Custom validation rule for floating-point numbers between 0 and 100
export const percentageValidation = extend(
  "percentageValidation",
  (value, args) => {
    const floatValue = parseFloat(value, 10);

    // Check if the value is a valid number between 0 and 100
    if (floatValue >= 0 && floatValue <= 100) {
      // Allow only 2 decimal places
      const regex = /^\d+(\.\d{1,2})?$/;

      if (regex.test(value)) {
        return true; // value is valid
      } else {
        // Return custom error message if available, or default message
        return args[1]
          ? args[1] // custom message for invalid decimal places
          : `Provide a valid percentage`; // default message
      }
    }

    // Return custom error message if available, or default message for out of range
    return args[0]
      ? args[0] // custom message
      : `Provide a valid percentage`; // default message
  }
);

// minimum value validation with customizing message
export const minIntegerValidation = extend("minimum", (value, args) => {
  if (parseInt(value, 10) >= parseInt(args[0], 10)) {
    return true;
  }
  return "The value should be greater than or equal to " + args;
});

// max length validation
export const maxLengthValidation = extend("max", (value, args) => {
  if (value.toString().length <= args.length) {
    return true;
  }
  return (
    "The value should not exceed more than " + args.length + " characters."
  );
});

// min length validation
export const minLengthValidation = extend("min", (value, args) => {
  if (value.toString().length >= args.length) {
    return true;
  }
  return "The value should be more than " + args.length + " characters.";
});

// alphabets, space and dots are allowed
export const alphaSpaceDotValidation = extend("alphaSpaceDot", (value) => {
  if (/^[a-zA-Z\.\ ]+$/.test(value)) {
    return true;
  }
  return "Only alphabets, space and dots are allowed.";
});

// alphabets, space, dots and underscore are allowed
export const alphaSpaceDotWithUnderScore = extend(
  "alphaSpaceDotUnderscore",
  (value) => {
    if (/^[a-zA-Z.\s_-]+$/.test(value)) {
      return true;
    }
    return "Only alphabets, space, dots, hyphen and underscore are allowed.";
  }
);
export const multilingualNameValidation = extend(
  "multilingualNameValidation",
  (value) => {
    if (/^[\p{L}\p{M}\s.,#\+&/\-():'ñd’!@#$%^&*±_=|\\;?"]+$/u.test(value)) {
      return true;
    }
    return "Only alphabets, space, dots, hyphen, ± and underscore are allowed.";
  }
);
export const multilingualNameNumericValidation = extend(
  "multilingualNameNumericValidation",
  (value) => {
    if (
      /^[\p{L}\p{M}\p{Nd}\s.,#\+&/\-():'ñd’!@#$%^&*±_=|\\;?"]+$/u.test(value)
    ) {
      return true;
    }
    return "Only alphanumeric, spaces, and symbols (. , # + & / - ( ):'ñd’ ! @ # ± $ % ^ & * _ =) are allowed.";
  }
);
export const positionValidation = extend("positionValidation", (value) => {
  if (/^[\p{L}\p{M}\p{Nd}\s.,#\+&/\-():'ñd’!@#]+$/u.test(value)) {
    return true;
  }
  return "Only alphaNumeric, space, dots, hyphen, ± and underscore are allowed.";
});
// pan card number validation
export const panNumber = extend("panNumber", (value) => {
  if (/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value)) {
    return true;
  }
  return "Invalid Tax Identification number";
});
export const aadhar = extend("aadhar", (value) => {
  if (/^[a-zA-Z0-9\|\\_\\.\,\#\+\&\/\-\(\)\:\`\'\ ]*$/.test(value)) {
    return true;
  }
  return "Invalid National Identity number";
});

// letters, numbers, spaces, and certain special characters are allowed
export const descriptionValidation = extend(
  "descriptionValidation",
  (value) => {
    if (/^[\w\s\d.,!@%^&*-_+=;:'"<>?/\()|`~’\n]+$/.test(value)) {
      return true;
    }
    return `Only letters, numbers, spaces, and special characters(. , ! @ % ^ & * - _ + = ; : ' " < > ( ) ?) are allowed.`;
  }
);

// alphabets and numerics are allowed
export const alphaNumericValidation = extend("alphaNumeric", (value) => {
  if (/^[a-zA-Z0-9]*$/.test(value)) {
    return true;
  }
  return "Only alphanumeric characters are allowed.";
});

// Alphabets, numerics, and dots are allowed
export const alphaNumDotValidation = extend("alphaNumDot", (value) => {
  if (/^[a-zA-Z0-9.]*$/.test(value)) {
    return true;
  }
  return "Only alphanumeric characters and dots are allowed.";
});

// alphabets, space are allowed
export const alphaSpaceValidation = extend("alphaSpace", (value) => {
  if (/^[a-zA-Z\ ]+$/.test(value)) {
    return true;
  }
  return "Only alphabets and space are allowed.";
});

// alphabets, space are allowed
export const alphaNumHySpaceValidation = extend("alphaNumHySpace", (value) => {
  if (/^[\w\-\ ]*$/.test(value)) {
    return true;
  }
  return "Only alphanumeric, space and hyphen are allowed.";
});

// alphanumeric, spaces, and symbols(& , ( ) .) are allowed
export const alphaNumSpaceWithFiveSymbolValidation = extend(
  "alphaNumSpaceWithFiveSymbol",
  (value) => {
    if (/^[\w\ \,\.\&\(\)\ ]+$/.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces, and symbols(& , ( ) .) are allowed";
  }
);

// alphanumeric, spaces and symbols(+ , / . # & : ( ) ' - ) are allowed
export const alphaNumSpaceWithElevenSymbolValidation = extend(
  "alphaNumSpaceWithElevenSymbol",
  (value) => {
    if (/^[\w\.\,\#\+\&\/\-\(\)\:\'\ ]*$/.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces and symbols(+ , / . # & : ( ) ' - ) are allowed.";
  }
);

// alphanumeric, spaces and symbols(- , ( ) )  are allowed
export const alphaNumSpaceWithFourSymbolValidation = extend(
  "alphaNumSpaceWithFourSymbol",
  (value) => {
    if (/^[\w\-\(\)\,\ ]*$/.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces and symbols(- , ( ) )  are allowed.";
  }
);

// alphanumeric, spaces and symbols(, () )  are allowed
export const alphaNumSpaceWithThreeSymbolValidation = extend(
  "alphaNumSpaceWithThreeSymbol",
  (value) => {
    if (/^[\w\(\)\,\ ]*$/.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces and symbols(, () )  are allowed.";
  }
);

// mobile & telephone number validation
export const mobileNoValidation = extend("mobileNo", (value) => {
  if (
    /^([\+][0-9]{1,3}[ \.\-])?([\(]{1}[0-9]{2,6}[\)])?([0-9 \+\.\-\/]{5,15})((x|ext|extension)[ ]?[0-9]{1,4})?$/.test(
      value
    )
  ) {
    return true;
  }
  return "Please provide a valid mobile number.";
});

// alphabets,spaces and symbols( - . () ) are allowed
export const alphaSpaceHyDotBracketsValidation = extend(
  "alphaSpaceHyDotBrackets",
  (value) => {
    if (/^[a-zA-Z\-\ \.\(\) ]*$/.test(value)) {
      return true;
    }
    return "Only alphabets,spaces and symbols( - . () ) are allowed.";
  }
);

export const alphaNumSpaceWithFiveSymbolsValidation = extend(
  "alphaNumSpaceWithFiveSymbols",
  (value) => {
    if (/^[\w\.\+\#\-\/\ ]*$/.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces and symbols(- . + / # )  are allowed.";
  }
);

// alphanumeric, spaces and symbols(, () )  are allowed
export const alphaNumSpaceWithTwoSymbolValidation = extend(
  "alphaNumSpaceWithTwoSymbol",
  (value) => {
    if (/^[\w\(\)\,\ ]*$/.test(value)) {
      return true;
    }
    return "Only alphanumeric,spaces and symbols(& - ) are allowed";
  }
);

export const bsbCodeValidation = extend("bsbCode", (value) => {
  if (/\d{3}-?\d{3}/.test(value)) {
    return true;
  }
  return "Please provide valid BSB code";
});

//IFSC validation
export const ifscValidation = extend("ifscCode", (value) => {
  if (/^[A-Z]{4}[0][A-Z0-9]{6}$/.test(value)) {
    return true;
  }
  return "Please provide valid {_field_} code";
});

export const alphaNumSpaceWithSymbolsValidation = extend(
  "alphaNumSpaceWithSymbols",
  (value) => {
    if (/^[a-zA-Z0-9\s&+,/#:()'.-]/.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces and symbols( + , / . # & : ( ) ' - ) are allowed.";
  }
);

export const numSpaceWithHyphenValidation = extend(
  "numSpaceHyphen",
  (value) => {
    if (/^(?=.*[0-9])[- 0-9]+$/.test(value)) {
      return true;
    }
    return "Only numeric, spaces and hyphen are allowed.";
  }
);

export const isNotValidation = extend("notSame", (value, args) => {
  if (value !== args[0]) return true;
  return "{_field_} should not be same as " + args[1];
});
